<template>
    <div class="edit-profile-detail">
        <div class="title f-22 m-l-20" v-html="$translate(options.title.toUpperCase())" />
        <div class="description f-13 m-l-20">
            뻔한 자기소개는 그만! 나를 잘 표현할 수 있는 <br />질문을 골라보세요. (매칭 확률에 크게 영향을 미쳐요)
        </div>
        <div class="description f-13 m-l-20 m-t-8 m-b-20">
            나의 가치관, 비젼을 진정성 있게 적거나, 때론 위트와 <br />재치있는 답변으로 회원님이 어떤 사람인지
            보여주세요.
        </div>
        <div class="section m-0 p-0" v-for="(item, idx) in storyExamples" :key="idx">
            <div
                v-if="profile.user_personal_stories.length > idx"
                class="written-story-icon"
                @click="onClickModify(idx)"
            >
                <img
                    class="complete"
                    :src="require(`@/assets/images/icons/story_complete.png`)"
                    alt="story-complete-icon"
                    width="28px"
                    height="28ox"
                />
            </div>
            <div
                v-if="profile.user_personal_stories.length > idx"
                class="written-story-wrapper m-l-20 m-r-20 m-b-16"
                @click="onClickModify(idx)"
            >
                <div class="story-title f-14 f-bold p-t-22 m-l-16 m-r-16">
                    {{ personalStories[profile.user_personal_stories[idx].personal_story_id - 1].name }}
                </div>
                <div class="story-content lines-2 f-14">{{ profile.user_personal_stories[idx].content }}</div>
            </div>
            <div
                v-if="profile.user_personal_stories.length <= idx"
                class="unwritten-story-icon"
                @click="onClickWrite(idx)"
            >
                <img
                    class="complete"
                    :src="require(`@/assets/images/icons/story_needed.png`)"
                    alt="story-needed-icon"
                    width="28px"
                    height="28ox"
                />
            </div>
            <div
                v-if="profile.user_personal_stories.length <= idx"
                class="unwritten-story-wrapper bg-white m-l-20 m-r-20 m-b-16"
                @click="onClickWrite(idx)"
            >
                <div class="story-title f-14 f-bold p-t-22 m-l-16">{{ item.title }}</div>
                <div class="story-content f-14 m-t-12 m-l-16 p-b-22">터치해서 질문을 선택해주세요.</div>
            </div>
        </div>
        <div class="requirement f-bold f-13 m-l-20 m-t-12" v-if="profile.user_personal_stories.length < 3">
            *답변 3개는 필수에요.
        </div>
        <BottomButton :greyBtn="true" :disabled="disabled" @click="submit" label="작성 완료하기" />
    </div>
</template>

<script>
// import SelectInput from '../app/SelectInput'
import profileService from '@/services/profile'

export default {
    name: 'PersonalStory',
    // components: {
    //     SelectInput,
    // },
    props: ['options'],
    data: () => ({
        showInput: false,
        userPersonalStories: null,
        personalStories: null,
    }),
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        disabled() {
            if (this.profile.user_personal_stories.length >= 3) {
                return false
            } else {
                return true
            }
        },
        storyExamples() {
            return [
                {
                    key: 1,
                    title: '첫번째 스토리',
                    required: true,
                },
                {
                    key: 2,
                    title: '두번째 스토리',
                    required: true,
                },
                {
                    key: 3,
                    title: '세번째 스토리',
                    required: true,
                },
            ]
        },
        // educationStatus() {
        //     const res = Object.keys(this.$profile.userEduStatus).map(key => this.$profile.userEduStatus[key])
        //     return this.type === 'high_school' ? res.filter(item => item.highSchool) : res
        // },
    },
    mounted() {
        this.init()
    },
    // watch: {
    //     async stage() {
    //         this.init()
    //     }
    // },
    methods: {
        async init() {
            await this.$store.dispatch('loadProfile')

            await profileService.userPersonalStories(this.$store.getters.me.id).then(res => {
                this.userPersonalStories = res.data
            })

            this.personalStories = await profileService.personalStories()
        },
        // onClickSelector(id) {
        //     this.selectedShowMethod = id
        // },
        // setSelectedEduStatus(item) {
        //     this.educationStatusSelected = item
        // },

        async onClickModify(idx) {
            const userPersonalStory = this.profile.user_personal_stories[idx]
            await this.$modal.custom({
                component: 'ModalStoryWriter',
                options: {
                    mode: 'modify',
                    profile: this.profile,
                    headerTitle: '퍼스널 스토리 작성하기',
                    stories: this.personalStories,
                    userPersonalStory: userPersonalStory,
                },
            })
        },

        async onClickWrite(idx) {
            await this.$modal.custom({
                component: 'ModalStorySelector',
                options: {
                    profile: this.profile,
                    headerTitle: '질문 선택하기',
                    stories: this.personalStories,
                },
            })

            // this.$stackRouter.push({
            //     name: 'StorySelector',

            //     props: {
            //         profile: this.profile,
            //         headerTitle: "질문 선택하기",
            //         stories: this.personalStories,
            //         userPersonalStory: null,
            //     },
            // })
        },
        async submit() {
            try {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: `profile_complete_onboard`,
                    },
                })

                this.$store.dispatch('loadProfile')
                this.$store.dispatch('loadMe')
                this.$stackRouter.pop()
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.title {
    color: #181f39;
    margin-top: 24px;
    text-align: left;
}

.description {
    color: #787878;
    line-height: 19.5px;
}

.requirement {
    color: #f64f56;
}

.written-story-icon {
    position: relative;
    z-index: 1;
    float: right;
    top: -6px;
    right: 14px;
}

.written-story-wrapper {
    color: #8a8a8a;
    background: #fdfcfa;
    border: 0.5px solid #151360;
    border-radius: 8px;
    position: relative;

    .story-title {
        color: #151360;
    }
    .story-content {
        color: #5c5c77;
        margin: 12px 16px 22px 16px;
    }
}

.unwritten-story-icon {
    position: relative;
    z-index: 1;
    float: right;
    top: -6px;
    right: 14px;
}

.unwritten-story-wrapper {
    color: #8a8a8a;
    border: 0.5px dashed #f64f56;
    border-radius: 8px;
    position: relative;
}
// .year {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     ::v-deep .textarea-with-x {
//         height: 48px;
//     }
// }
</style>
